const NotFound = () => {
	return (
		<div className='w-full flex flex-col justify-center items-center mt-10'>
			<div className='text-primary text-7xl font-bold'>404</div>
			<div className='mt-4'>Không tìm thấy trang!</div>
		</div>
	);
};

export default NotFound;
