import { Outlet } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar';
import BarsIcon from '../svgs/BarsIcon';

const Layout = () => {
  return (
    <div className='flex h-screen bg-white dark:bg-gray-900 w-full'>
      <div className='md:hidden'>
        <BarsIcon />
      </div>
      <Sidebar />
      <div className='flex flex-col flex-1 w-full'>
        <main className='h-full overflow-y-auto'>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
