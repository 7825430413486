import axios, { AxiosRequestHeaders } from 'axios';
import { KEY_TOKEN } from '../constants/app-const';

const defaultHeader = (token?: string | null) => {
	const header: AxiosRequestHeaders = {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
	};
	if (token) header.Authorization = `Bearer ${token}`;
	return header;
};

class ApiRequests {
	public get<P>(apiUrl: string, params?: P, isAuth = true, responseType?: 'json' | 'blob') {
		const headers = defaultHeader(isAuth ? localStorage.getItem(KEY_TOKEN) : null);
		return new Promise((resolve, reject) => {
			axios
				.get(apiUrl, { headers, params, responseType })
				.then((res) => resolve(res.data))
				.catch((err) => reject(err));
		});
	}

	public post<T>(apiUrl: string, data?: T, isAuth = true) {
		const headers = defaultHeader(isAuth ? localStorage.getItem(KEY_TOKEN) : null);
		return new Promise((resolve, reject) => {
			axios
				.post(apiUrl, data, { headers })
				.then((res) => resolve(res.data))
				.catch((err) => reject(err));
		});
	}

	public put<T>(apiUrl: string, data?: T, isAuth = true) {
		const headers = defaultHeader(isAuth ? localStorage.getItem(KEY_TOKEN) : null);
		return new Promise((resolve, reject) => {
			axios
				.put(apiUrl, data, { headers })
				.then((res) => resolve(res.data))
				.catch((err) => reject(err));
		});
	}

	public delete<T>(apiUrl: string, data?: T, isAuth = true) {
		const headers = defaultHeader(isAuth ? localStorage.getItem(KEY_TOKEN) : null);
		return new Promise((resolve, reject) => {
			axios
				.delete(apiUrl, { headers, data })
				.then((res) => resolve(res.data))
				.catch((err) => reject(err));
		});
	}
}

export const Requests = new ApiRequests();
