import { put, takeLatest } from '@redux-saga/core/effects';
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
  showMessage,
} from '../../app';
import { BASE_URL, ENDPOINTS } from '../../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IResponseCreateAccount } from './createAccount.interface';
import { CreateAccountTypes, IAdminCreateAccountAction } from './createAccount.type';

function* createAccount({ type, payload }: IAdminCreateAccountAction) {
  yield put(handleActionRequested(type));
  try {
    const urlCreateHospital = BASE_URL.dev + ENDPOINTS.hospitals;
    const urlCreateAccount = BASE_URL.dev + ENDPOINTS.createAgency;

    const hospital: AxiosResponse = yield Requests.post(urlCreateHospital, {
      name: payload.hospitalName,
      address: 'example',
      phone_number: '0935341748',
    });

    const account: AxiosResponse = yield Requests.post(urlCreateAccount, {
      email: payload.email,
      password: payload.password,
      role: 'agency',
      hospital_id: hospital.data._id,
    });

    if (account.data) {
      yield put(handleActionSucceeded<IResponseCreateAccount>(type, account.data));
      yield put(showMessage('Thêm tài khoản thành công', 'success'));
    } else {
      yield put(handleActionFailed(type, ''));
    }
  } catch (error: any) {
    const message = error.response.data.message ? error.response.data.message : error.message;
    yield put(handleActionFailed(type, message));
  }
}

function* watchCreateAccount() {
  yield takeLatest(CreateAccountTypes.ADMIN_CREATE_ACCOUNT, createAccount);
}

export function* rootCreateAccount() {
  yield all([fork(watchCreateAccount)]);
}
