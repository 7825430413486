import { Reducer } from 'redux';
import {
	GetHospitalInforAction,
	GetHospitalInforTypes,
	IGetHospitalInforAction,
} from './hospitalInfor.type';
import { initialResponseState, IResponseState } from '../state';
import { GlobalReducer } from '../app';

export const HospitalInforReducer: Reducer<IResponseState, IGetHospitalInforAction> = (
	state = initialResponseState,
	action: GetHospitalInforAction,
) => GlobalReducer(state, action, GetHospitalInforTypes.GET_HOSPITAL_INFOR.toString());
