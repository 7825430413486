import React from 'react';
import Layout from './layout';
import { Login } from './pages/login';
import { RouteObject } from 'react-router-dom';
import HospitalSetting from './pages/hospital-setting';
import { PrivatePageAdmin, PrivatePageAgency, PrivatePageClient } from './components/PrivatePage';
import NotFound from './pages/notfound';
import { LoginAdmin } from './pages/login/LoginAdmin';
import MonthReport from './pages/admin/admin-history-reports/month-report';

const Dashboard = React.lazy(() => import('./pages/admin/dashboard'));
const ReportsHistory = React.lazy(() => import('./pages/admin/admin-history-reports'));
const Hospital = React.lazy(() => import('./pages/hospital'));
const SendReport = React.lazy(() => import('./pages/send-report'));

export const protectedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <PrivatePageClient element={<Layout />} />,
    children: [
      {
        path: '/admin/dashboard',
        element: <PrivatePageAdmin element={<Dashboard />} />,
      },
      {
        path: '/admin/admin-history-reports',
        element: <PrivatePageAdmin element={<ReportsHistory />} />,
      },
      {
        path: 'admin/hospital',
        element: <PrivatePageAdmin element={<Hospital />} />,
      },
      {
        path: 'hospital-detail',
        element: <PrivatePageAgency element={<Hospital />} />,
      },
      {
        path: 'admin/hospital-setting',
        element: <PrivatePageAdmin element={<HospitalSetting />} />,
      },
      {
        path: 'send-report',
        element: <PrivatePageAgency element={<SendReport />} />,
      },
      {
        path: 'hospital-reports',
        element: <PrivatePageClient element={<ReportsHistory />} />,
      },
      {
        path: '/hospital/month-report',
        element: <PrivatePageClient element={<MonthReport />} />,
      },
    ],
  },
  {
    path: 'admin/login',
    element: <LoginAdmin />,
  },
  {
    path: '/',
    index: true,
    element: <Login />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
