import { put, takeEvery } from '@redux-saga/core/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IGetAllUserAction, IResponseGetAllUser } from './allUsers.interface';
import { GetAllUserTypes } from './allUsers.type';

function* getAllUser({ type, payload }: IGetAllUserAction) {
	yield put(handleActionRequested(type));

	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.getAllUser;
		const response: AxiosResponse = yield Requests.get(apiUrl);
		if (response.data) {
			yield put(handleActionSucceeded<IResponseGetAllUser>(type, response.data));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (error: any) {
		const message = error.response.data.message ? error.response.data.message : error.message;
		yield put(handleActionFailed(type, message));
	}
}

function* watchGetAllUser() {
	yield takeEvery(GetAllUserTypes.GET_ALL_USER, getAllUser);
}

export function* rootGetAllUser() {
	yield all([fork(watchGetAllUser)]);
}
