import { all, fork } from 'redux-saga/effects';
import { put, takeLatest } from '@redux-saga/core/effects';
import { ILoginRequestAction, LoginTypes } from './login.type';
import { Requests } from '../../api/requests';
import { AxiosResponse } from 'axios';
import { IResponseLogin } from './login.interface';
import {
	handleActionFailed,
	handleActionRequested,
	handleActionSucceeded,
	showMessage,
} from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';

function* login({ payload, type }: ILoginRequestAction) {
	yield put(handleActionRequested(type));
	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.login;
		const res: AxiosResponse<IResponseLogin> = yield Requests.post(apiUrl, {
			...payload,
		});
		if (res.data) {
			yield put(handleActionSucceeded<IResponseLogin>(type, res.data));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (err: any) {
		const message = err.response.data.message ? err.response.data.message : err.message;
		yield put(handleActionFailed(type, message));
	}
}
function* watchLogin() {
	yield takeLatest(LoginTypes.LOGIN, login);
}

export function* rootLogin() {
	yield all([fork(watchLogin)]);
}
