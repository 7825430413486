import { IGetReportsAction } from './reports.interface';

export type GetReportsParams = {
	hospital_name?: string;
	createdMonthVN?: string;
	sortBy?: any;
	pageSize?: any;
	page?: any;
};

export enum GetReportsTypes {
	GET_REPORTS = 'GET_REPORTS',
}

export type GetReportsAction = IGetReportsAction;
