export const BASE_URL = {
  dev:
    process.env.NODE_ENV === 'production'
      ? 'https://api.nhsm.bmte.vn/api/v1'
      : process.env.REACT_APP_API || 'http://localhost:8080/api/v1',
};

export const ENDPOINTS = {
  login: '/auth/login',
  createAgency: '/user/create-agency',
  createHospital: '/hospitals/hospital-detail',
  hospitals: '/hospitals',
  getAllUser: '/user/all-users',
  getFormReport: '/reports/get-form',
  sendReport: '/reports',
  getProfile: '/user/me',
  getHospitalReports: '/reports/my-reports',
  getReports: '/reports/all-reports',
  getReportsExcel: '/reports/all-reports-xlsx',
  getReportsByHospital: '/reports/report-by-hospital',
};
