import { Controller, Control, FieldValues, RegisterOptions, FieldPath } from 'react-hook-form';

type InputBaseProps = {
  placeholder?: string;
  icon?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  defaultValue?: string | number | readonly string[];
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[];
  error?: any;
  textCenter?: boolean;
  disabled?: boolean;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};
type InputControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<InputBaseProps, 'onChange' | 'value'> & {
  control: Control<any, any>;
  key?: React.Key;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

export const InputBase: React.FC<InputBaseProps> = ({
  placeholder,
  icon,
  type = 'text',
  defaultValue,
  name,
  onChange,
  value,
  error,
  textCenter = false,
  disabled = false,
  className = 'py-2 px-4',
}) => {
  return (
    <div className='w-full'>
      <div
        className={`flex relative rounded-md border !border-green overflow-hidden ${
          error ? 'border-red-600 border' : ''
        }`}
      >
        {icon && (
          <span className='inline-flex items-center pl-4 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-100 text-gray-500 text-sm'>
            {icon}
          </span>
        )}
        <input
          type={type}
          className={`flex-1 appearance-none w-20 ${className} ${
            disabled ? 'bg-slate-300 cursor-not-allowed' : ''
          } dark:bg-gray-800 dark:text-gray-100 text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-0 focus:border-transparent`}
          placeholder={placeholder}
          defaultValue={defaultValue}
          name={name}
          onFocus={(e) => (e.target.placeholder = '')}
          disabled={disabled}
          onChange={onChange}
          onBlur={(e) => (e.target.placeholder = placeholder || '')}
          value={value}
          style={{
            textAlign: textCenter ? 'center' : 'left',
          }}
        />
      </div>
      {error && (
        <span className='text-[10px] text-red-600'>{error}</span>
      )}
    </div>
  );
};

export const InputController: React.FC<InputControllerProps> = ({
  placeholder,
  icon,
  type = 'text',
  defaultValue = '',
  name = '',
  control,
  key,
  rules,
  error,
  textCenter = false,
  disabled = false,
  className,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <InputBase
          placeholder={placeholder}
          icon={icon}
          type={type}
          onChange={onChange}
          value={value}
          error={error}
          textCenter={textCenter}
          disabled={disabled}
          className={className}
        />
      )}
      defaultValue={defaultValue}
      key={key}
      rules={rules}
    />
  );
};
