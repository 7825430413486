import { SingleLink } from '../SingleLink';
import { HomeIcon } from '../../svgs/HomeIcon';
import { HospitalBuilding } from '../../svgs/HospitalBuilding';
import { useLocation } from 'react-router-dom';
import { isAdmin, isAgency, isClient } from '../../helpers';
import FolderPlus from '../../svgs/FolderPlus';
import logo from '../../assets/logo-nhsm/Logo_HMB.png';
import da_nang from '../../assets/logo-nhsm/da_nang.png';
import hung_vuong from '../../assets/logo-nhsm/hung_vuong.png';
import nhi_tw from '../../assets/logo-nhsm/nhi_tw.png';
import phuong_chau from '../../assets/logo-nhsm/phuong_chau.png';
import quang_nam from '../../assets/logo-nhsm/quang_nam.png';
import quang_ninh from '../../assets/logo-nhsm/quang_ninh.png';
import tu_du from '../../assets/logo-nhsm/tu_du.png';
import CLipboardDocument from '../../svgs/ClipboardDocument';
import { KEY_INFO_USER } from '../../constants/app-const';
import { useSelector } from 'react-redux';
import { IAppState } from '../../stores/state';
import { Logout } from '../Logout';

export const Sidebar = () => {
  const { pathname } = useLocation();

  const getLogo = (name: string) => {
    if (!name) return logo;
    const lowercaseName = name.toLowerCase();
    if (lowercaseName.includes('quảng ninh')) return quang_ninh;
    if (lowercaseName.includes('đà nẵng')) return da_nang;
    if (lowercaseName.includes('từ dũ')) return tu_du;
    if (lowercaseName.includes('nhi trung ương')) return nhi_tw;
    if (lowercaseName.includes('quảng nam')) return quang_nam;
    if (lowercaseName.includes('phương châu')) return phuong_chau;
    if (lowercaseName.includes('hùng vương')) return hung_vuong;
    return logo;
  };

  const userInfor = JSON.parse(localStorage.getItem(KEY_INFO_USER) as string);
  const hospitalProfile = useSelector((state: IAppState) => state.profile);

  return (
    <aside className='z-20 flex-shrink-0 hidden w-64 overflow-y-auto bg-sidebar dark:bg-slate-800 md:block min-h-screen'>
      <div className='flex flex-col text-gray-500 dark:text-gray-400 min-h-screen'>
        <div className='w-full flex justify-center'>
          <div className='w-44 h-36'>
            <img
              className='object-cover'
              src={getLogo(hospitalProfile.data?.hospital?.name)}
              alt='logo'
            />
          </div>
        </div>
        <ul className='flex flex-col'>
          <SingleLink
            name='Tổng quan'
            to={isAdmin() ? '/admin/dashboard' : '/hospital-detail'}
            canAccess={isAdmin() || isAgency() || isClient()}
            icon={<HomeIcon />}
          />
          <SingleLink
            name='Tạo báo cáo mới'
            to='/send-report'
            canAccess={userInfor.user.role === 'agency'}
            icon={<FolderPlus />}
          />
          <SingleLink
            name='Lịch sử báo cáo'
            to={isAdmin() ? '/admin/admin-history-reports' : '/hospital-reports'}
            canAccess={isAdmin() || isAgency() || isClient()}
            icon={<CLipboardDocument />}
          />
          <SingleLink
            name='Quản lý người dùng'
            to='/admin/hospital-setting'
            canAccess={isAdmin()}
            icon={
              <HospitalBuilding
                fill={pathname === '/admin/hospital-setting' ? '#FFFFFF' : '#6B7290'}
              />
            }
          />
        </ul>
        <div className='mt-auto px-6 mb-4'>
          <Logout role={isAdmin() ? 'admin' : isAgency() ? 'agency' : 'client'} />
        </div>
      </div>
    </aside>
  );
};
