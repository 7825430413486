import { indexInputs } from 'constants/data';
import moment from 'moment';
import { PERMISSIONS } from '../constants/app-const';
import { Category, Subcategory } from '../stores/form-report';
import { Report } from '../stores/hospital-reports';

const groupBy = require('lodash/groupBy');

export const formatPercentNumber = (num: any) => {
  if (!isNaN(num)) return `${num[0]}${num[1]}.${num[2]}${num[3]}`;
  return num;
};

// export const formatIntegerNumber = (num: any) => {
//   if (num && String(num).includes('.')) {
//     return String(num)
//       .replaceAll('.', '')
//       .replace(/(.)(?=(\d{3})+$)/g, '$1.');
//   }
//   return String(num).replace(/(.)(?=(\d{3})+$)/g, '$1.');
// };

export const formatIntegerNumber = (input: number | '-') => {
  if (input === '-') {
    return '-';
  }

  if (typeof input !== 'number') {
    return 'Invalid';
  }

  // Check if the number is a decimal
  if (Number.isInteger(input)) {
    return input.toLocaleString(); // Format integers with commas
  } else {
    return input.toFixed(2); // Round to 2 decimal places
  }
};

export const convertToInterger: any = (num: string) => {
  if (typeof num === 'string' && num.includes('.')) {
    return Number(num.replaceAll('.', ''));
  }
  return Number(num) || 0;
};

export const calculateSum = (arr: number[]) => {
  let sum = 0;

  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }

  return sum;
};

export const calculateAverage = (arr: number[]) => {
  let sum = 0;

  if (arr.length == 0) return sum;

  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }

  return sum / arr.length;
};

export const distanceDate = (reportTime: string) => {
  const reportDate = moment(reportTime).locale('vi').fromNow();
  return reportDate;
};

export const getPermissions = localStorage.getItem(PERMISSIONS)
  ? JSON.parse(localStorage.getItem(PERMISSIONS) as any)
  : [];

export const adminPermissions = [
  'user_w',
  'user_r',
  'control_user',
  'hospital_w',
  'hospital_r',
  'categories_w',
  'categories_r',
  'sub_categories_w',
  'sub_categories_r',
  'activities_w',
  'activities_r',
  'reports_w',
  'reports_r',
  'reports_r_all',
];
export const agencyPermissions = ['user_r', 'reports_r', 'reports_w'];
export const clientPermissions = ['reports_r', 'hospital_r', 'user_r'];

export const isAdmin = () => {
  const permissions = localStorage.getItem(PERMISSIONS);
  if (!permissions) {
    return false;
  }
  const permissionParsed = JSON.parse(permissions);
  return adminPermissions.every((per) => permissionParsed.includes(per));
};

export const isAgency = () => {
  const permissions = localStorage.getItem(PERMISSIONS);
  if (!permissions) {
    return false;
  }
  const permissionParsed = JSON.parse(permissions);
  return agencyPermissions.every((per) => permissionParsed.includes(per));
};

export const isClient = () => {
  const permissions = localStorage.getItem(PERMISSIONS);
  if (!permissions) {
    return false;
  }
  const permissionParsed = JSON.parse(permissions);
  return clientPermissions.every((per) => permissionParsed.includes(per));
};

export const sortCategory = (data: Category[]) => {
  return data.sort((a, b) => parseInt(a.no) - parseInt(b.no));
};

export const sortSubCategory = (subCategories: Subcategory[]) => {
  return subCategories.sort(
    (a, b) => parseInt(a.index.split('_')[1]) - parseInt(b.index.split('_')[1]),
  );
};

// Lấy giá trị của một index trong báo cáo
export const getValueIndex = (reports: Report[], monthVN: string, indexes: string[]) => {
  let value: any = 0;
  reports?.forEach((r) => {
    if (r && r?.createdMonthVN === monthVN) {
      r?.data?.forEach((category) => {
        category.subcategories.forEach((sub) => {
          if (indexes.includes(sub.index)) {
            if (!Number(sub.currentValue)) {
              value = sub.currentValue;
            } else {
              value =
                Number(String(value).replaceAll('-', '')) +
                Number(String(sub.currentValue).replaceAll('-', '0'));
            }
          }
        });
      });
    }
  });
  return value;
};

// Lượng sữa TB mỗi BMHT cho NHSM = sum chỉ số 16 tất cả các tháng có số liệu / chỉ số 15
export const getAverageMilk = (reports: Report[], monthVN: string) => {
  var sumIndex16: any = 0;
  const valueIndex15 = getValueIndex(reports, monthVN, [indexInputs.index5_3]);
  reports?.forEach((report) => {
    report?.data?.forEach((category) => {
      category.subcategories.forEach((sub) => {
        if (sub.index === indexInputs.index5_4) {
          sumIndex16 += sub.currentValue;
        }
      });
    });
  });

  if (!valueIndex15) return 0;

  if (valueIndex15 === '-') {
    return '-';
  }

  return Math.floor(sumIndex16 / valueIndex15) || 0;
};

export const getAverageMilkYear = (reports: Report[]) => {
  const totalIndex15 = getTotalIndex(reports, indexInputs.index5_3);
  const totalIndex16 = getTotalIndex(reports, indexInputs.index5_4);

  if (!totalIndex15) return 0;

  return Math.round(totalIndex16 / totalIndex15) || 0;
};

export const getTotalIndex = (reports: Report[], index: string) => {
  if (!reports) return 0;

  let total = 0;
  let checkPercent = false;

  reports.forEach((report) => {
    report.data.forEach((category) => {
      category.subcategories.forEach((sub) => {
        if (sub.index === index && sub.currentValue !== '-') {
          total += sub.currentValue;
          if (sub.measure === '%') {
            checkPercent = true;
          }
        }
      });
    });
  });

  if (checkPercent) {
    return total / reports.length;
  } else {
    return total;
  }
};

export const getTotalActivity = (reports: Report[], index: string) => {
  if (!reports) return 0;

  let total = 0;

  reports.forEach((report) => {
    report.data.forEach((category) => {
      category.subcategories.forEach((sub) => {
        // if (sub.activities.length > 0 && index <= sub.activities.length - 1) {
        //   total += sub.activities[index].currentValue;
        // } else {
        //   console.log('sub.activities', { sub: sub.activities, index });
        // }
        sub.activities.length > 0 &&
          sub.activities.forEach((activity) => {
            if (activity.index === index) {
              total += activity.currentValue;
            }
          });
      });
    });
  });

  return total;
};

export const removeDashFromDatasets = (datasets: any[], labels: any[]) => {
  const newDatasets = [];
  const newLabels = [];

  for (let i = 0; i < datasets.length; i++) {
    if (datasets[i] !== '-') {
      newDatasets.push(datasets[i]);
      newLabels.push(labels[i]);
    }
  }

  return [newDatasets, newLabels];
};

export const reporterInfor = (
  name: string,
  email: string,
  phone: string,
  role: string,
  department: string,
) => {
  const data: string = `{"name": "${name}", "email": "${email}","phone": "${phone}", "role": "${role}", "department": "${department}"}`;
  return data;
};
