import React from 'react';
import { Skeleton } from 'antd';

const SkeletonLoading: React.FC = () => (
	<div className='my-4'>
		<Skeleton />
	</div>
);

export default SkeletonLoading;
