import { GlobalReducer } from '../app';
import { Reducer } from 'redux';
import { initialResponseState, IResponseState } from '../state';
import { IGetAllUserAction } from './allUsers.interface';
import { GetAllUserAction, GetAllUserTypes } from './allUsers.type';

export const AllUsersReducer: Reducer<IResponseState, IGetAllUserAction> = (
	state = initialResponseState,
	action: GetAllUserAction,
) => GlobalReducer(state, action, GetAllUserTypes.GET_ALL_USER.toString());
