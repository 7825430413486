import { Report } from '../stores/hospital-reports';

export const generatePastMonths = (startYear: number, startMonth: number) => {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1; // add 1 since getMonth() returns zero-based month


  let months = [];

  for (let year = startYear; year <= currentYear; year++) {
    let endMonth = year === currentYear ? currentMonth : 12;

    for (let month = startMonth; month <= endMonth; month++) {
      months.push(`T${month}/${year}`);
    }

    startMonth = 1; // Reset startMonth to 1 for subsequent years
  }

  return { monthsList: months, currentMonth: `T${currentMonth}/${currentYear}` };
};

export const sortByTime = (reports: Report[]) => {
  let outputReport: Report[] = [];
  // Extract the month and year from each object and convert them to numbers
  const monthAndYearArr = reports.map((obj) => obj.createdMonthVN.slice(1).split('/').map(Number));

  // Sort the array by year, then by month
  const sortedArr = monthAndYearArr.sort((a, b) => {
    if (a[1] !== b[1]) {
      return a[1] - b[1];
    } else {
      return a[0] - b[0];
    }
  });

  // Convert the month and year back to strings and assign them to the createdMonthVN property
  sortedArr.map((arr, index) => {
    const month = arr[0];
    const year = arr[1];

    const foundReport = reports.find(
      (report) => report.createdMonthVN === 'T' + month + '/' + year,
    );
    if (foundReport) {
      outputReport.push(foundReport);
    }
  });

  return outputReport;
};
