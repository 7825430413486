import { combineReducers } from 'redux';
import { LoginReducer } from './login';
import { IAppState } from './state';
import { AppMessageReducer } from './app';
import { HospitalsSettingReducer } from './dashboard/admin';
import { FormReportReducer } from './form-report';
import { SendReportReducer } from './send-report';
import { GetHospitalsReducer, HospitalDetailReducer } from './hospital-detail';
import { ProfileReducer } from './profile';
import { HospitalReportsReducer } from './hospital-reports';
import { GetReportsReducer } from './reports/reports.reducer';
import { HospitalInforReducer } from './hospital-information';
import { AllUsersReducer } from './all-users';
import { DeleteHospitalReducer } from './delete-hospital';
import { CreateAccountReducer } from './dashboard/create-account';
import { DeleteReportReducer } from './delete-report';

// Combine reducers
export const rootReducer = combineReducers<IAppState>({
  appMessage: AppMessageReducer,
  login: LoginReducer,
  hospitalsSetting: HospitalsSettingReducer,
  formReport: FormReportReducer,
  sendReport: SendReportReducer,
  hospitalDetail: HospitalDetailReducer,
  hospitals: GetHospitalsReducer,
  profile: ProfileReducer,
  hospitalReports: HospitalReportsReducer,
  reports: GetReportsReducer,
  hospitalInformation: HospitalInforReducer,
  allUser: AllUsersReducer,
  deleteHospital: DeleteHospitalReducer,
  createAccount: CreateAccountReducer,
  deleteReport: DeleteReportReducer,
});
