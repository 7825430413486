import { Tooltip } from 'antd';
import { MouseEventHandler, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonVariant = 'Text' | 'Contained' | 'Outlined' | 'Rounded' | 'ContainedFloat';

type ButtonProps = {
  title: string | React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  variant: ButtonVariant;
  tooltip?: string;
  notiText?: string;
};

export const IconButton: React.FC<ButtonProps> = ({
  title,
  startIcon,
  endIcon,
  loading = false,
  disabled = false,
  onClick = () => {},
  variant = 'Text',
  tooltip,
  notiText,
}) => {
  const isDisabled = useMemo(() => disabled || loading, [disabled, loading]);

  const disabledStyle = isDisabled ? 'pointer-events-none bg-gray-300' : 'pointer-events-auto';

  let buttonClass = '';

  switch (variant) {
    case 'Contained':
      buttonClass = 'bg-blue hover:bg-blue text-white';
      break;
    case 'ContainedFloat':
      buttonClass = 'bg-blue hover:bg-blue text-white shadow-lg';
      break;
    case 'Outlined':
      buttonClass = 'border border-blue text-blue hover:border-blue hover:text-blue';
      break;
    case 'Rounded':
      buttonClass = 'rounded-full bg-blue hover:bg-blue text-white';
      break;
    case 'Text':
    default:
      buttonClass = 'text-black hover:text-blue';
  }

  // bg-blue text-white shadow-md hover:bg-sky-700 hover:shadow-sm

  return (
    <Tooltip title={tooltip}>
      <button
        type='submit'
        onClick={onClick}
        disabled={disabled}
        className={twMerge(
          'relative flex h-10 items-center justify-center px-2 text-base font-semibold rounded-md focus:outline-none',
          disabledStyle,
          buttonClass,
        )}
      >
        {loading ? (
          <div
            className='spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent'
            role='status'
          />
        ) : (
          <>
            {!!startIcon && startIcon}
            <span className='mx-1'>{title}</span>
            {!!endIcon && endIcon}
            {notiText && (
              <div className='absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-600 px-2.5 py-1 text-center align-baseline text-xs font-bold leading-none text-white'>
                {notiText}
              </div>
            )}
          </>
        )}
      </button>
    </Tooltip>
  );
};
