import { put, takeEvery } from '@redux-saga/core/effects';
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
  showMessage,
} from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IDeleteReportAction, IResponseDeleteReport } from './deleteReport.interface';
import { DeleteReportTypes } from './deleteReport.type';

function* deleteReport({ type, payload }: IDeleteReportAction) {
  yield put(handleActionRequested(type));

  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.sendReport + '/' + payload.id;
    const response: AxiosResponse = yield Requests.delete(apiUrl);
    if (response.data) {
      yield put(handleActionSucceeded<IResponseDeleteReport>(type, response.data));
      yield put(showMessage('Xóa báo cáo thành công', 'success'));
    } else {
      yield put(handleActionFailed(type, ''));
    }
  } catch (error: any) {
    const message = error.response.data.message ? error.response.data.message : error.message;
    yield put(handleActionFailed(type, message));
  }
}

function* watchDeleteReport() {
  yield takeEvery(DeleteReportTypes.DELETE_REPORT, deleteReport);
}

export function* rootDeleteReport() {
  yield all([fork(watchDeleteReport)]);
}
