import { put, takeEvery } from '@redux-saga/core/effects';
import {
	handleActionFailed,
	handleActionRequested,
	handleActionSucceeded,
	showMessage,
} from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IDeleteHospitalAction, IResponseDeleteHospital } from './deleteHospital.interface';
import { DeleteHospitalTypes } from './deleteHospital.type';

function* deleteHospital({ type, payload }: IDeleteHospitalAction) {
	yield put(handleActionRequested(type));

	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.hospitals + '/' + payload.id;
		const response: AxiosResponse = yield Requests.delete(apiUrl);
		if (response.data) {
			yield put(handleActionSucceeded<IResponseDeleteHospital>(type, response.data));
			yield put(showMessage('Xóa ngân hàng sữa mẹ thành công', 'success'));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (error: any) {
		const message = error.response.data.message ? error.response.data.message : error.message;
		yield put(handleActionFailed(type, message));
	}
}

function* watchDeleteHospital() {
	yield takeEvery(DeleteHospitalTypes.DELETE_HOSPITAL, deleteHospital);
}

export function* rootDeleteHospitalDetail() {
	yield all([fork(watchDeleteHospital)]);
}
