// axiosInterceptors.js
import axios from 'axios';

const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        return logout();
      }
      return Promise.reject(error);
    },
  );
};

const logout = () => {
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('InfoUser');
  localStorage.removeItem('Permissions');
  localStorage.removeItem('user');

  // Force reload with logout query
  window.location.href = window.location.protocol + '//' + window.location.host + '?logout=true';
};

export default setupAxiosInterceptors;
