import { IFormLogin } from "./login.interface";

export enum LoginTypes {
  LOGIN = "LOGIN",
}

export interface ILoginRequestAction {
  type: typeof LoginTypes.LOGIN;
  payload: IFormLogin;
}

export type LoginActions = ILoginRequestAction;
