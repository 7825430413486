import { put, takeLeading } from '@redux-saga/core/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IGetProfileAction, IResponseGetProfile } from './profile.interface';
import { GetProfileTypes } from './profile.type';

function* getProfile({ type }: IGetProfileAction) {
	yield put(handleActionRequested(type));

	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.getProfile;
		const response: AxiosResponse = yield Requests.get(apiUrl);
		if (response.data) {
			yield put(handleActionSucceeded<IResponseGetProfile>(type, response.data));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (error: any) {
		const message = error.response.data.message ? error.response.data.message : error.message;
		yield put(handleActionFailed(type, message));
	}
}

function* watchGetProfile() {
	yield takeLeading(GetProfileTypes.GET_PROFILE, getProfile);
}

export function* rootGetProfile() {
	yield all([fork(watchGetProfile)]);
}
