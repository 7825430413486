export enum GetHospitalInforTypes {
	GET_HOSPITAL_INFOR = 'GET_HOSPITAL_INFOR',
}

export interface IGetHospitalInforAction {
	type: GetHospitalInforTypes.GET_HOSPITAL_INFOR;
	payload: string;
}

export type GetHospitalInforAction = IGetHospitalInforAction;
