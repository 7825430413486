import { HospitalDetailTypes } from './hospitalDetail.type';
import {
  IGetHospitalAction,
  IGetHospitalParams,
  IGetHospitalsAction,
} from './hospitalDetail.interface';

export const getHospital = (payload?: IGetHospitalParams): IGetHospitalAction => ({
  type: HospitalDetailTypes.GET_HOSPITAL,
  payload,
});

export const getHospitals = (): IGetHospitalsAction => ({
  type: HospitalDetailTypes.GET_HOSPITALS,
});
