import { put, takeLatest } from '@redux-saga/core/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IGetHospitalReportsAction, IResponseGetHospitalReports } from './hospitalReport.interface';
import { HospitalReportTypes } from './hospitalReport.type';
import { sortByTime } from '../../utils/months';

function* getHospitalReports({ type, hospital_name }: IGetHospitalReportsAction) {
  yield put(handleActionRequested(type));

  try {
    let params = {};
    if (hospital_name) {
      params = { hospital_name };
    }

    const response: AxiosResponse = yield Requests.get(BASE_URL.dev + ENDPOINTS.getReports, params);
    
    if (response.data.data) {
      yield put(
        handleActionSucceeded<IResponseGetHospitalReports>(type, {
          data: sortByTime(response.data.data),
        }),
      );
    } else {
      yield put(handleActionFailed(type, ''));
    }
  } catch (error: any) {
    const message = error?.response?.data?.message || error.message;
    yield put(handleActionFailed(type, message));
  }
}

function* watchGetHospitalReports() {
  yield takeLatest(HospitalReportTypes.GET_HOSPITAL_REPORTS, getHospitalReports);
}

export function* rootGetHospitalReports() {
  yield all([fork(watchGetHospitalReports)]);
}
