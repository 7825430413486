import { GlobalReducer } from '../app';
import { Reducer } from 'redux';
import { initialResponseState, IResponseState } from '../state';
import { IDeleteHospitalAction } from './deleteHospital.interface';
import { DeleteHospitalAction, DeleteHospitalTypes } from './deleteHospital.type';

export const DeleteHospitalReducer: Reducer<IResponseState, IDeleteHospitalAction> = (
	state = initialResponseState,
	action: DeleteHospitalAction,
) => GlobalReducer(state, action, DeleteHospitalTypes.DELETE_HOSPITAL.toString());
