import { put, takeEvery } from '@redux-saga/core/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import {
  IGetHospitalAction,
  IGetHospitalsAction,
  IResponseGetHospitalDetail,
} from './hospitalDetail.interface';
import { HospitalDetailTypes } from './hospitalDetail.type';

function* getHospital({ type, payload }: IGetHospitalAction) {
  yield put(handleActionRequested(type));

  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.hospitals + payload;
    const response: AxiosResponse = yield Requests.get(apiUrl);
    if (response.data) {
      yield put(handleActionSucceeded<IResponseGetHospitalDetail>(type, response.data));
    } else {
      yield put(handleActionFailed(type, ''));
    }
  } catch (error: any) {
    const message = error.response.data.message ? error.response.data.message : error.message;
    yield put(handleActionFailed(type, message));
  }
}

function* watchGetHospital() {
  yield takeEvery(HospitalDetailTypes.GET_HOSPITAL, getHospital);
}

function* getHospitals({ type }: IGetHospitalsAction) {
  yield put(handleActionRequested(type));

  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.hospitals;
    const response: AxiosResponse = yield Requests.get(apiUrl);
    if (response.data) {
      yield put(handleActionSucceeded<IResponseGetHospitalDetail>(type, response.data));
    } else {
      yield put(handleActionFailed(type, ''));
    }
  } catch (error: any) {
    const message = error.response.data.message ? error.response.data.message : error.message;
    yield put(handleActionFailed(type, message));
  }
}

function* watchGetHospitals() {
  yield takeEvery(HospitalDetailTypes.GET_HOSPITALS, getHospitals);
}

export function* rootGetHospitalDetail() {
  yield all([fork(watchGetHospital), fork(watchGetHospitals)]);
}
