import { GlobalReducer } from '../app';
import { Reducer } from 'redux';
import { initialResponseState, IResponseState } from '../state';
import { GetProfileAction, GetProfileTypes } from './profile.type';
import { IGetProfileAction } from './profile.interface';

export const ProfileReducer: Reducer<IResponseState, IGetProfileAction> = (
	state = initialResponseState,
	action: GetProfileAction,
) => GlobalReducer(state, action, GetProfileTypes.GET_PROFILE.toString());
