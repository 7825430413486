import { IAdminGetHospitals } from './admin.interface';

export enum AdminTypes {
	ADMIN_GET_HOSPITALS = 'ADMIN_GET_HOSPITALS',
}

export interface IAdminGetHospitalsAction {
	type: AdminTypes.ADMIN_GET_HOSPITALS;
	payload: IAdminGetHospitals;
}

export type AdminGetHospitalsActions = IAdminGetHospitalsAction;
