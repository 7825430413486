import { AdminTypes, IAdminGetHospitalsAction } from './admin.type';
import { put, takeEvery } from '@redux-saga/core/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../../app';
import { BASE_URL, ENDPOINTS } from '../../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IResponseGetHospitalsSetting } from './admin.interface';

function* getHospitals({ type, payload }: IAdminGetHospitalsAction) {
	yield put(handleActionRequested(type));
	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.hospitals;
		const response: AxiosResponse<IResponseGetHospitalsSetting> = yield Requests.get(apiUrl, {
			...payload,
		});
		if (response.data) {
			yield put(handleActionSucceeded<IResponseGetHospitalsSetting>(type, response.data));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (error: any) {
		const message = error.response.data.message ? error.response.data.message : error.message;
		yield put(handleActionFailed(type, message));
	}
}

function* watchGetHospitals() {
	yield takeEvery(AdminTypes.ADMIN_GET_HOSPITALS, getHospitals);
}

export function* rootGetHospitals() {
	yield all([fork(watchGetHospitals)]);
}
