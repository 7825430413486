import { IAdminCreateAccount } from "./createAccount.interface";

export enum CreateAccountTypes {
	ADMIN_CREATE_ACCOUNT = 'ADMIN_CREATE_ACCOUNT',
}

export interface IAdminCreateAccountAction {
	type: CreateAccountTypes.ADMIN_CREATE_ACCOUNT;
	payload: IAdminCreateAccount;
}

export type AdminCreateAccount = IAdminCreateAccountAction;
