import { FormReportTypes, IFormReportAction } from './formReport.type';
import { put, takeEvery } from '@redux-saga/core/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IResponseGetFormReport } from './formReport.interface';

function* getFormReport({ type }: IFormReportAction) {
	yield put(handleActionRequested(type));

	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.getFormReport;
		const response: AxiosResponse = yield Requests.get(apiUrl);
		if (response.data) {
			yield put(handleActionSucceeded<IResponseGetFormReport>(type, response));
			localStorage.setItem('DataReport', JSON.stringify(response.data));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (error: any) {
		const message = error.response.data.message ? error.response.data.message : error.message;
		yield put(handleActionFailed(type, message));
	}
}

function* watchGetFormReport() {
	yield takeEvery(FormReportTypes.GET_FORM, getFormReport);
}

export function* rootGetFormReport() {
	yield all([fork(watchGetFormReport)]);
}
