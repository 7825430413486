import { IAppMessageState } from './app';
import { IResponseLogin } from './login';
import { IResponseGetHospitalsSetting } from './dashboard/admin';
import { IResponseGetFormReport } from './form-report';
import { IResponseSendReport } from './send-report';
import { IHospitalsOutput, IResponseGetHospitalDetail } from './hospital-detail';
import { IResponseGetProfile } from './profile';
import { IResponseGetHospitalReports } from './hospital-reports';
import { IResponseGetReports } from './reports/reports.interface';
import { IResponseGetHospitalInfor } from './hospital-information';
import { IResponseGetAllUser } from './all-users';
import { IResponseDeleteHospital } from './delete-hospital';
import { IResponseCreateAccount } from './dashboard/create-account';
import { IResponseDeleteReport } from './delete-report';

export interface IResponseState<T = any> {
  isLoading: boolean;
  data: T;
  message?: string;
}

export const initialResponseState: IResponseState<null> = {
  isLoading: false,
  data: null,
  message: '',
};

export interface IGlobalAction<T = any> extends Partial<IResponseState> {
  type: any;
  data?: T;
}

export type GlobalActions<T = any> = IGlobalAction<T>;

export interface IAppState {
  appMessage: IAppMessageState;
  login: IResponseState<IResponseLogin>;
  hospitalsSetting: IResponseState<IResponseGetHospitalsSetting>;
  formReport: IResponseState<IResponseGetFormReport>;
  sendReport: IResponseState<IResponseSendReport>;
  hospitalDetail: IResponseState<IResponseGetHospitalDetail>;
  hospitals: IResponseState<IHospitalsOutput>;
  profile: IResponseState<IResponseGetProfile>;
  hospitalReports: IResponseState<IResponseGetHospitalReports>;
  reports: IResponseState<IResponseGetReports>;
  hospitalInformation: IResponseState<IResponseGetHospitalInfor>;
  allUser: IResponseState<IResponseGetAllUser>;
  deleteHospital: IResponseState<IResponseDeleteHospital>;
  deleteReport: IResponseState<IResponseDeleteReport>;
  createAccount: IResponseState<IResponseCreateAccount>;
}
