import { Category } from '../form-report';
import { IGetHospitalReportsAction } from './hospitalReport.interface';

export type Report = {
	_id: string;
	user_id: string;
	createdAtVN: string;
	createdMonthVN: string;
	hospital_name: string;
	reporter: string;
	createdAt: string;
	updatedAt: string;
	data: Category[];
};

export enum HospitalReportTypes {
	GET_HOSPITAL_REPORTS = 'GET_HOSPITAL_REPORTS',
}

export type HospitalReportsAction = IGetHospitalReportsAction;
