import { all, fork } from 'redux-saga/effects';
import { put, takeLatest } from '@redux-saga/core/effects';
import { Requests } from '../../api/requests';
import { AxiosResponse } from 'axios';
import {
	handleActionFailed,
	handleActionRequested,
	handleActionSucceeded,
	showMessage,
} from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { SendReportTypes } from './sendReport.type';
import { IResponseSendReport, ISendReportAction } from './sendReport.interface';

function* sendReport({ payload, type }: ISendReportAction) {
	yield put(handleActionRequested(type));
	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.sendReport;
		const res: AxiosResponse<IResponseSendReport> = yield Requests.post(apiUrl, {
			...payload,
		});
		if (res.data) {
			yield put(handleActionSucceeded<IResponseSendReport>(type, res.data));
			yield put(showMessage('Gửi báo cáo thành công!', 'success'));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (err: any) {
		const message = err.response.data.message ? err.response.data.message : err.message;
		yield put(handleActionFailed(type, message));
	}
}
function* watchSendReport() {
	yield takeLatest(SendReportTypes.SEND_REPORT, sendReport);
}

export function* rootSendReport() {
	yield all([fork(watchSendReport)]);
}
