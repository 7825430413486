import { Disclosure } from '@headlessui/react';
import { IconButton } from 'components/Button';
import { InputBase } from 'components/Input';
import { Typography } from 'components/Typography';
import { formatIntegerNumber } from 'helpers';
import queryString from 'query-string';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getReports } from 'stores/reports/reports.action';
import { IAppState } from 'stores/state';
import DownloadIcon from 'svgs/DownloadIcon';

type MonthReportProps = {};

const MonthReport: FC<MonthReportProps> = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'onChange' });

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  const parseQuery = queryString.parse(location.search);

  const searchReportResult = useSelector((state: IAppState) => state.reports.data);

  const reporterInfor =
    searchReportResult && searchReportResult.data[0].reporter
      ? JSON.parse(searchReportResult.data[0].reporter)
      : {
          name: '',
          role: '',
          department: '',
          phone: '',
          email: '',
        };

  const componentPrintRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    documentTitle: `Báo cáo NHSM ${searchReportResult?.data[0]?.hospital_name} ${searchReportResult?.data[0]?.createdMonthVN}`,
  });

  useEffect(() => {
    dispatch(getReports(parseQuery));
  }, []);

  return (
    <div className='w-full'>
      <div ref={componentPrintRef}>
        <header className='flex justify-between items-center sticky top-0 z-10 bg-slate-50 py-3 px-14'>
          <IconButton
            title='Quay lại'
            onClick={() => {
              navigate(-1);
            }}
            variant='Contained'
          />

          <IconButton
            startIcon={<DownloadIcon stroke='#fff' />}
            title='Xuất báo cáo'
            onClick={handlePrint}
            variant='Contained'
          />
        </header>

        {searchReportResult && (
          <div ref={componentPrintRef} className='w-full md:px-14'>
            <div>
              <div className='!bg-blue pl-4 my-2 py-4 flex flex-col items-start text-white'>
                <div>
                  <p className='font-bold text-2xl'>BÁO CÁO KẾT QUẢ HOẠT ĐỘNG NGÂN HÀNG SỮA MẸ</p>
                </div>
                <div className='flex gap-3 items-center'>
                  <h2 className='text-xl font-normal'>
                    {searchReportResult?.data[0]?.hospital_name}
                  </h2>
                  <span className='w-2 h-2 bg-white rounded-full'></span>
                  <h2 className='text-xl font-normal'>
                    {searchReportResult?.data[0]?.createdMonthVN}
                  </h2>
                </div>
              </div>
              <div>
                {searchReportResult?.data[0]?.data?.map((category, index) => (
                  <div key={index} className='w-full mt-6 bg-slate-50'>
                    <div className='mb-2'>
                      <Disclosure defaultOpen>
                        {({ open }) => (
                          <>
                            <div className='flex items-center justify-between mb-3 w-full !bg-pink px-2 py-1'>
                              <Typography variant='h3' className='text-left text-white' bold>
                                {category.name}
                              </Typography>
                            </div>
                            <Disclosure.Panel>
                              {category?.subcategories?.map((sub, index_sub) => {
                                return (
                                  <div key={index_sub} className='mb-3'>
                                    <div className='bg-slate-100 px-2 mx-8 py-1 flex justify-between'>
                                      <Typography className='flex items-center max-w-[75%]'>
                                        {sub.index.replaceAll('_', '.')}. {sub.sub_name}
                                      </Typography>
                                      <div className='flex items-center gap-2'>
                                        {/* {editing ? (
                                          <Controller
                                            name={sub._id}
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                              <InputBase
                                                onChange={(e) => onChange(e)}
                                                value={
                                                  value && !sub.sub_name.includes('%')
                                                    ? formatIntegerNumber(sub.currentValue)
                                                    : sub.currentValue
                                                }
                                                textCenter
                                                className='py-1 w-20'
                                                placeholder='0'
                                                error={
                                                  errors?.[sub._id]?.message &&
                                                  errors?.[sub._id]?.message
                                                }
                                              />
                                            )}
                                            rules={{
                                              required: 'Trường này là bắt buộc.',
                                              pattern: {
                                                value: sub.sub_name.includes('%')
                                                  ? /^[1-9]?[0-9]{1}$|^100$|^[1-9]{1,2}\.[1-9]{1,2}$/g
                                                  : /^[1-9]*.?\d*.?\d*.?\d*$/g,
                                                message: 'Không đúng định dạng',
                                              },
                                            }}
                                          />
                                        ) : (
                                          
                                        )} */}
                                        <span className='float-right font-bold'>
                                          {formatIntegerNumber(sub.currentValue)}
                                        </span>
                                        <span className='w-[80px]'>{sub.measure || ''}</span>
                                      </div>
                                    </div>
                                    <div className='pl-8'>
                                      {sub?.activities?.length > 0 && (
                                        <table className='table-auto w-full'>
                                          <tbody>
                                            {sub?.activities?.map((activity, index_activity) => (
                                              <tr key={index_activity}>
                                                <td className='pl-5 pr-10 py-1 flex items-center justify-between'>
                                                  <Typography className='w-2/3'>
                                                    {activity?.name}
                                                  </Typography>
                                                  <div className='flex gap-2 justify-between items-center'>
                                                    <span className='float-right font-bold'>
                                                      {formatIntegerNumber(activity?.currentValue)}
                                                    </span>
                                                    <span className='w-[80px]'>
                                                      {activity?.measure || ''}
                                                    </span>
                                                  </div>
                                                </td>
                                                {activity?.sub_activities?.map((subActivity) => {
                                                  return (
                                                    <td
                                                      key={subActivity?._id}
                                                      className='px-10 py-1 flex items-center justify-between'
                                                    >
                                                      <Typography>{subActivity?.name}</Typography>
                                                      <div className='flex gap-2 justify-between items-center'>
                                                        <span className='float-right font-bold'>
                                                          {subActivity?.currentValue}
                                                        </span>

                                                        <span className='w-[80px]'>mL</span>
                                                      </div>
                                                    </td>
                                                  );
                                                })}
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                ))}
                <div className='mx-auto my-16 flex flex-col gap-1 xl:w-3/5 lg:w-4/5 md:w-4/5 sm:w-5/6 border border-indigo-100 bg-slate-300 rounded-lg shadow-xl pt-6 pb-8 px-6 '>
                  <Typography bold className='text-lg text-center'>
                    Thông tin người điền báo cáo
                  </Typography>
                  <div className='grid grid-cols-2 gap-4'>
                    <div>
                      <Typography className='text-sm'>Họ tên đầy đủ:</Typography>
                      <InputBase
                        disabled
                        value={reporterInfor.name}
                        placeholder='Chưa có thông tin'
                      />
                    </div>
                    <div>
                      <Typography className='text-sm'>Chức vụ:</Typography>
                      <InputBase
                        disabled
                        value={reporterInfor.role}
                        placeholder='Chưa có thông tin'
                      />
                    </div>
                    <div>
                      <Typography className='text-sm'>Khoa phòng:</Typography>
                      <InputBase
                        disabled
                        value={reporterInfor.department}
                        placeholder='Chưa có thông tin'
                      />
                    </div>
                    <div>
                      <Typography className='text-sm'>Số điện thoại:</Typography>
                      <InputBase
                        disabled
                        value={reporterInfor.phone}
                        placeholder='Chưa có thông tin'
                      />
                    </div>
                    <div>
                      <Typography className='text-sm'>Email:</Typography>
                      <InputBase
                        disabled
                        value={reporterInfor.email}
                        placeholder='Chưa có thông tin'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthReport;
