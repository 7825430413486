import { GlobalReducer } from '../app';
import { Reducer } from 'redux';
import { initialResponseState, IResponseState } from '../state';
import { IGetHospitalReportsAction } from './hospitalReport.interface';
import { HospitalReportsAction, HospitalReportTypes } from './hospitalReport.type';

export const HospitalReportsReducer: Reducer<IResponseState, IGetHospitalReportsAction> = (
	state = initialResponseState,
	action: HospitalReportsAction,
) => GlobalReducer(state, action, HospitalReportTypes.GET_HOSPITAL_REPORTS.toString());
