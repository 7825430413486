import { useMemo } from 'react';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';

export type SingleLinkProps = {
	icon?: React.ReactNode;
	name: string;
	path?: string;
	id: string;
	canAccess?: boolean;
};

const MilkBankLink: React.FC<SingleLinkProps> = ({ icon, id, name, path, canAccess = false }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const handleNavigate = () => {
		setSearchParams(
			createSearchParams({
				id: id,
			}),
		);
	};

	const isActive = useMemo(() => {
		return searchParams.get('id') === id;
	}, [id, searchParams]);

	if (!canAccess) return null;

	return (
		<td className='block w-full' onClick={handleNavigate}>
			<Link
				className={`inline-block text-sm font-medium rounded-md py-3 pl-4 pr-24 bg-gray-300 min-h-max w-full ${
					isActive ? '!bg-blue text-white font-bold' : ''
				}`}
				to=''
			>
				{name}
			</Link>
		</td>
	);
};

export default MilkBankLink;
