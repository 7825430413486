import { Dialog, Transition } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { Modal } from 'antd';
import { BASE_URL } from 'api/endpoint';
import { IconButton } from 'components/Button';
import { FC, Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Requests } from '../../api/requests';
import MilkBankLink from '../../components/MilkBankLink';
import SkeletonLoading from '../../components/Skeleton';
import { getAllUser } from '../../stores/all-users';
import { createAccount } from '../../stores/dashboard/create-account';
import { deleteHospital } from '../../stores/delete-hospital';
import { IAppState } from '../../stores/state';
import PlusIcon from '../../svgs/PlusIcon';

type HospitalSettingProps = {};

const HospitalSetting: FC<HospitalSettingProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const allUser = useSelector((state: IAppState) => state.allUser);
  const { data: createAccountResult, isLoading: loadingCreate } = useSelector(
    (state: IAppState) => state.createAccount,
  );
  const { data: deleteAccountResult, isLoading: loadingDelete } = useSelector(
    (state: IAppState) => state.deleteHospital,
  );

  const mutation = useMutation({
    mutationFn: (email: string) => {
      return Requests.post(`${BASE_URL.dev}/auth/admin-reset-password`, { email });
    },
  });

  const [openConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const [titleConfirm, setTitleConfirm] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    register,
  } = useForm<any>({ mode: 'onChange' });

  useEffect(() => {
    dispatch(getAllUser({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAccountResult, deleteAccountResult]);

  useEffect(() => {
    if (allUser.data) {
      setSearchParams(
        createSearchParams({
          id: allUser.data?.data.filter((user) => user.hasOwnProperty('hospital'))[0].hospital._id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUser.data]);

  useEffect(() => {
    mutation.reset();
  }, [searchParams]);

  const handleDelete = () => {
    setTitleConfirm('Bạn có chắc muốn xóa ngân hàng này?');
    onOpenModal();
  };

  const onCloseModal = () => {
    setIsOpenConfirm(false);
  };
  const onOpenModal = () => {
    setIsOpenConfirm(true);
  };

  const handleDeleteHospital = async () => {
    await dispatch(deleteHospital({ id: searchParams.get('id') as string }));
    onCloseModal();
  };

  const onOkModal = () => {
    setOpenModal(false);
    const value = getValues();
    dispatch(createAccount({ ...value }));
  };

  const handleNewPassword = () => {
    mutation.mutate(
      allUser.data?.data?.find((user) => user.hospital?._id === searchParams.get('id'))
        ?.email as string,
    );
  };

  const current_hospital = allUser.data?.data?.find(
    (user) => user.hospital?._id === searchParams.get('id'),
  );

  return (
    <div className='p-8'>
      {/* Modal add account */}
      <Modal
        title='Thêm tài khoản'
        open={openModal}
        onOk={onOkModal}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        footer=''
      >
        <label className='font-medium' htmlFor=''>
          Tên ngân hàng sữa mẹ
        </label>
        <br />
        <input
          required
          className='w-full p-2 border border-blue'
          {...register('hospitalName', { required: true })}
          type='text'
        />

        <br />
        <div className='h-4'></div>
        <label className='font-medium' htmlFor=''>
          Tên đăng nhập
        </label>
        <br />
        <input
          className='w-full p-2 border border-blue'
          {...register('email', { required: true })}
          type='email'
        />

        <br />
        <div className='h-4'></div>
        <label className='font-medium' htmlFor=''>
          Mật khẩu
        </label>
        <br />
        <input
          className='w-full p-2 border border-blue'
          {...register('password', { required: true, minLength: 6 })}
          type='text'
        />
        <br />
        <div className='mt-2 text-red-500'>
          {Object.keys(errors).length !== 0 && (
            <ul>
              <li>1. Hãy đảm bảo điền đủ các trường</li>
              <li>2. Username chuẩn định dạng: nhsm.ten_ngan_hang_sua_me (Ví dụ: nhsm.quangninh)</li>
              <li>3. Mật khẩu tối thiểu 6 ký tự</li>
            </ul>
          )}
        </div>
        <br />
        {loadingCreate ? (
          <div
            className='spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent'
            role='status'
          />
        ) : (
          <button
            onClick={handleSubmit(onOkModal)}
            className='py-2 px-4 rounded-md text-white font-bold bg-blue'
          >
            Thêm
          </button>
        )}
      </Modal>
      <div className='grid grid-rows-1 gap-4 md:grid-cols-2'>
        <div>
          <div className='flex items-center justify-between'>
            <p className='mr-5 text-xl font-bold'>Danh sách ngân hàng sữa mẹ</p>
            <button
              onClick={() => {
                setOpenModal(true);
              }}
              className='flex justify-between items-center p-2 text-white text-sm font-medium rounded !bg-blue transition-all'
            >
              <span className='mr-2'>
                <PlusIcon />
              </span>
              <span>Thêm tài khoản</span>
            </button>
          </div>
          <div>
            {allUser.isLoading ? (
              <SkeletonLoading />
            ) : (
              <table className='table-fixed w-full'>
                <tbody>
                  {allUser.data?.data &&
                    allUser?.data?.data?.map(
                      (user) =>
                        user.hospital && (
                          <tr key={user._id} className='block my-3'>
                            <MilkBankLink
                              id={user.hospital._id}
                              name={user.hospital.name}
                              canAccess
                            />
                          </tr>
                        ),
                    )}
                </tbody>
              </table>
            )}
          </div>
          {/* render danh sach benh vien */}
        </div>
        <div className='px-4 bg-white/80 rounded-md'>
          <h2 className='text-xl font-bold pt-2'>
            Ngân hàng sữa mẹ{' '}
            {
              allUser.data?.data?.find((user) => user.hospital?._id === searchParams.get('id'))
                ?.hospital.name
            }
          </h2>
          <div className='pt-2 flex flex-col gap-2'>
            <p className='text-sm font-medium'>Username</p>
            <span className='p-3 border border-blue rounded-md w-full'>
              {
                allUser.data?.data?.find((user) => user.hospital?._id === searchParams.get('id'))
                  ?.email
              }
            </span>
          </div>

          <div className='pt-2 flex flex-col gap-2'>
            <p className='text-sm font-medium'>Password</p>
            <span className='p-3 border border-blue rounded-md w-full'>
              {
                allUser.data?.data?.find((user) => user.hospital?._id === searchParams.get('id'))
                  ?.password
              }
            </span>
          </div>

          {mutation.isSuccess && (
            <>
              <div className='flex flex-row mt-4 items-center gap-2'>
                <b>Mật khẩu mới là:</b>
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `Đăng nhập NHSM ${
                        current_hospital?.hospital.name
                      } trên website https://nhsm.bmte.vn:\nEmail: ${
                        current_hospital?.email
                      }\nMật khẩu: ${
                        (mutation.data as any).data.newPassword
                      }\n\nTạo tự động bởi hệ thống NHSM, vào lúc ${new Date().toLocaleString(
                        'vi',
                      )}`,
                    );
                    toast.success(
                      'Đã copy thông tin đăng nhập mới vào bộ nhớ đệm. Cán bộ Y tế hãy dán vào tin nhắn Zalo và gửi đến NHSM nhé! Xin cảm ơn!',
                    );
                  }}
                  className='px-4 py-1 bg-black text-white rounded-md cursor-pointer'
                >
                  {(mutation.data as any).data.newPassword}
                </span>
              </div>
              <i className='mt-4'>
                Mật khẩu này được bảo mật và chỉ hiển thị 1 lần duy nhất. Anh/chị hãy{' '}
                <b>click vào ô màu đen trên để sao chép</b> và lưu lại trước khi rời đi nhé ạ!
              </i>
            </>
          )}

          <div className='w-full mx-auto mt-4 text-sm font-medium flex flex-row items-start gap-3'>
            <button
              onClick={handleNewPassword}
              className='px-4 py-2 rounded-md text-white !bg-green transition-all'
            >
              Tạo mật khẩu mới
            </button>

            <button
              onClick={handleDelete}
              className='px-4 py-2 rounded-md border border-red-400 text-red-400 transition-all'
            >
              Xóa Ngân hàng sữa mẹ
            </button>
          </div>
        </div>
      </div>
      <Transition appear show={openConfirm} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-xl bg-white py-5 px-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-xl text-center font-semibold leading-6 text-gray-900 mb-4'
                  >
                    {titleConfirm}
                  </Dialog.Title>

                  <div className='mt-4 flex justify-end gap-2'>
                    <div>
                      <IconButton
                        variant='Contained'
                        title='Tôi chắc chắn'
                        onClick={handleDeleteHospital}
                      />
                    </div>
                    <button
                      type='button'
                      onClick={onCloseModal}
                      className='rounded-md px-4 py-2 font-bold border border-blue text-blue hover:bg-blue hover:text-white transition-all'
                    >
                      Khoan đã
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HospitalSetting;
