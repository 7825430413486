import { GetHospitalInforTypes, IGetHospitalInforAction } from './hospitalInfor.type';
import { put, takeEvery } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { all, fork } from 'redux-saga/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { Requests } from '../../api/requests';

function* getHospitalInfor({ type, payload }: IGetHospitalInforAction) {
	yield put(handleActionRequested(type));
	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.hospitals + `/${payload}`;
		const response: AxiosResponse = yield Requests.get(apiUrl);
		if (response.data) {
			yield put(handleActionSucceeded(type, response));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (error: any) {
		const message = error.response.data.message ? error.response.data.message : error.message;
		yield put(handleActionFailed(type, message));
	}
}

function* watchGetHospitalInfor() {
	yield takeEvery(GetHospitalInforTypes.GET_HOSPITAL_INFOR, getHospitalInfor);
}

export function* rootGetHospitalInfor() {
	yield all([fork(watchGetHospitalInfor)]);
}
