import { Reducer, AnyAction } from 'redux';
import { GlobalActions, IResponseState } from '../state';
import { IAppMessageState, initialAppMessageState } from './app.inteface';
import { AppTypes } from './app.type';

// Initial global reducer
export const GlobalReducer = (state: IResponseState, action: Partial<GlobalActions>, actionType: string): IResponseState => {
	switch (action.type) {
		case `${actionType}_REQUESTED`:
			return {
				...state,
				isLoading: true,
				data: null,
				message: '',
			};
		case `${actionType}_SUCCEEDED`:
			return {
				...state,
				isLoading: false,
				data: action.data,
				message: action?.message,
			};
		case `${actionType}_FAILED`:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
};

export const AppMessageReducer: Reducer = (state = initialAppMessageState, action: AnyAction): IAppMessageState => {
	if (action.type.indexOf('_FAILED') !== -1) {
		return {
			...state,
			message: action.message,
			isOpen: true,
			severity: 'error',
		};
	}
	switch (action.type) {
		case AppTypes.APP_MESSAGE_SHOW: {
			return {
				...state,
				message: action.message,
				isOpen: true,
				severity: action.severity,
			};
		}
		case AppTypes.APP_MESSAGE_HIDE: {
			return {
				...state,
				message: '',
				isOpen: false,
				severity: 'error',
			};
		}
		default:
			return {
				...state,
				severity: 'success',
			};
	}
};
