import { initialResponseState, IResponseState } from '../state';
import { Reducer } from 'redux';
import { LoginActions, LoginTypes } from './login.type';
import { GlobalReducer } from '../app';
import { KEY_INFO_USER, KEY_TOKEN, PERMISSIONS } from '../../constants/app-const';

const initialLoginState = {
	...initialResponseState,
	data:
		localStorage.getItem(KEY_INFO_USER) &&
		localStorage.getItem(KEY_TOKEN) &&
		localStorage.getItem(PERMISSIONS)
			? {
					user: JSON.parse(localStorage.getItem(KEY_INFO_USER) as any).user,
					access_token: localStorage.getItem(KEY_TOKEN),
					permissions: JSON.parse(localStorage.getItem(PERMISSIONS) as any),
			  }
			: null,
};

export const LoginReducer: Reducer<IResponseState, LoginActions> = (
	state = initialLoginState,
	action: LoginActions,
) => GlobalReducer(state, action, LoginTypes.LOGIN.toString());
