import { faker } from '@faker-js/faker';

export const historyReport = [
  {
    _id: '63a08f44e3ab20bbdd793846',
    name: 'Thông tin liên quan tới sử dụng sữa tại khoa, phòng',
    subcategories: [
      {
        _id: '63a0928fe3ab20bbdd79387e',
        sub_name: 'Số trẻ mới nhận sữa từ NHSM',
        category_id: '63a08f44e3ab20bbdd793846',
        createdAt: '2022-12-19T16:34:23.057Z',
        updatedAt: '2022-12-19T16:34:23.057Z',
        activities: [],
        currentValue: 12,
      },
      {
        _id: '63a09297e3ab20bbdd793880',
        sub_name: 'Thời gian dùng SMHT trung bình của trẻ',
        category_id: '63a08f44e3ab20bbdd793846',
        createdAt: '2022-12-19T16:34:31.915Z',
        updatedAt: '2022-12-19T16:34:31.915Z',
        activities: [],
        currentValue: 66,
      },
      {
        _id: '63a092a1e3ab20bbdd793882',
        sub_name: 'Lượng SMTT cho trẻ ăn (mL)',
        category_id: '63a08f44e3ab20bbdd793846',
        createdAt: '2022-12-19T16:34:41.776Z',
        updatedAt: '2022-12-19T16:34:41.776Z',
        activities: [],
        currentValue: 98,
      },
      {
        _id: '63a092abe3ab20bbdd793884',
        sub_name: 'Số trẻ có biến cố bất lợi sau khi sử dụng SMTT',
        category_id: '63a08f44e3ab20bbdd793846',
        createdAt: '2022-12-19T16:34:51.798Z',
        updatedAt: '2022-12-19T16:34:51.798Z',
        activities: [],
        currentValue: 562,
      },
    ],
  },
  {
    _id: '63a08f52e3ab20bbdd793848',
    name: 'Giám sát hỗ trợ',
    subcategories: [
      {
        _id: '63a092c6e3ab20bbdd793886',
        sub_name: 'Số lần giám sát hỗ trợ/ kiểm tra tiến hành',
        category_id: '63a08f52e3ab20bbdd793848',
        createdAt: '2022-12-19T16:35:18.869Z',
        updatedAt: '2022-12-19T16:35:18.869Z',
        activities: [],
        currentValue: 8,
      },
    ],
  },
  {
    _id: '63a08ed8e3ab20bbdd793840',
    name: 'Thông tin chung từ bệnh viện',
    subcategories: [
      {
        _id: '63a09037e3ab20bbdd79384c',
        sub_name: 'Số trẻ sinh thường',
        category_id: '63a08ed8e3ab20bbdd793840',
        createdAt: '2022-12-19T16:24:23.389Z',
        updatedAt: '2022-12-19T16:24:23.389Z',
        activities: [],
        currentValue: 65,
      },
      {
        _id: '63a09044e3ab20bbdd79384e',
        sub_name: 'Số trẻ sinh mổ',
        category_id: '63a08ed8e3ab20bbdd793840',
        createdAt: '2022-12-19T16:24:36.157Z',
        updatedAt: '2022-12-19T16:24:36.157Z',
        activities: [],
        currentValue: 77,
      },
      {
        _id: '63a09050e3ab20bbdd793850',
        sub_name: 'Số trẻ đẻ nhẹ cân (< 2.500 g)',
        category_id: '63a08ed8e3ab20bbdd793840',
        createdAt: '2022-12-19T16:24:48.523Z',
        updatedAt: '2022-12-19T16:24:48.523Z',
        activities: [],
        currentValue: 65,
      },
      {
        _id: '63a0905ce3ab20bbdd793852',
        sub_name: 'Số trẻ sinh thiếu tháng (< 37 tuần)',
        category_id: '63a08ed8e3ab20bbdd793840',
        createdAt: '2022-12-19T16:25:00.208Z',
        updatedAt: '2022-12-19T16:25:00.208Z',
        activities: [],
        currentValue: 65,
      },
      {
        _id: '63a09068e3ab20bbdd793854',
        sub_name:
          'Số trẻ nhập khoa hồi sức tích cực sơ sinh (NICU). Có thể chia theo nhóm nguyên nhân (nếu cho phép)',
        category_id: '63a08ed8e3ab20bbdd793840',
        createdAt: '2022-12-19T16:25:12.086Z',
        updatedAt: '2022-12-19T16:25:12.086Z',
        activities: [],
        currentValue: 65,
      },
      {
        _id: '63a0907ee3ab20bbdd793856',
        sub_name: 'Số trẻ sinh ra bởi bà mẹ nhiễm HIV/AIDS',
        category_id: '63a08ed8e3ab20bbdd793840',
        createdAt: '2022-12-19T16:25:34.010Z',
        updatedAt: '2022-12-19T16:25:34.010Z',
        activities: [],
        currentValue: 78,
      },
      {
        _id: '63a09084e3ab20bbdd793858',
        sub_name: 'Số trẻ mồ côi hoặc bị bỏ rơi',
        category_id: '63a08ed8e3ab20bbdd793840',
        createdAt: '2022-12-19T16:25:40.996Z',
        updatedAt: '2022-12-19T16:25:40.996Z',
        activities: [],
        currentValue: 6,
      },
    ],
  },
  {
    _id: '63a08fc8e3ab20bbdd79384a',
    name: 'Một số chỉ số bổ sung',
    subcategories: [
      {
        _id: '63a092e4e3ab20bbdd793888',
        sub_name: 'Lượng sữa trung bình mỗi trẻ ăn (mL)',
        category_id: '63a08fc8e3ab20bbdd79384a',
        createdAt: '2022-12-19T16:35:48.653Z',
        updatedAt: '2022-12-19T16:35:48.653Z',
        activities: [],
        currentValue: 57,
      },
      {
        _id: '63a092ede3ab20bbdd79388a',
        sub_name: 'Tỷ lệ trẻ sử dụng sữa/ Tổng số trẻ sinh tại BV (%)',
        category_id: '63a08fc8e3ab20bbdd79384a',
        createdAt: '2022-12-19T16:35:57.742Z',
        updatedAt: '2022-12-19T16:35:57.742Z',
        activities: [],
        currentValue: 24,
      },
      {
        _id: '63a092f5e3ab20bbdd79388c',
        sub_name: 'Lượng sữa trung bình mỗi BMHT tặng NHSM (mL)',
        category_id: '63a08fc8e3ab20bbdd79384a',
        createdAt: '2022-12-19T16:36:05.590Z',
        updatedAt: '2022-12-19T16:36:05.590Z',
        activities: [],
        currentValue: 55,
      },
    ],
  },
  {
    _id: '63a08f2de3ab20bbdd793844',
    name: 'Thông tin liên quan tới tiếp nhận, xử lý, bảo quản, và phân phát sữa tại NHSM. Lượng sữa tính bằng mili lít (mL)',
    subcategories: [
      {
        _id: '63a09163e3ab20bbdd793866',
        sub_name: 'Tổng số SMHT thô (mL, SMHT)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:29:23.666Z',
        updatedAt: '2022-12-19T16:29:23.666Z',
        activities: [],
        currentValue: 22,
      },
      {
        _id: '63a0916ce3ab20bbdd793868',
        sub_name: 'Tổng số SMHT thô đem thanh trùng (mL)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:29:32.788Z',
        updatedAt: '2022-12-19T16:29:32.788Z',
        activities: [],
        currentValue: 11,
      },
      {
        _id: '63a09175e3ab20bbdd79386a',
        sub_name: 'Tổng số loạt thanh trùng (loạt)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:29:41.806Z',
        updatedAt: '2022-12-19T16:29:41.806Z',
        activities: [],
        currentValue: 99,
      },
      {
        _id: '63a09181e3ab20bbdd79386c',
        sub_name: 'Tổng số SMTT đạt sau xét nghiệm vi sinh (mL)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:29:53.251Z',
        updatedAt: '2022-12-19T16:29:53.251Z',
        activities: [],
        currentValue: 456,
      },
      {
        _id: '63a0918ce3ab20bbdd79386e',
        sub_name: 'Tổng số SMTT không đạt sau xét nghiệm vi sinh (mL)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:30:04.937Z',
        updatedAt: '2022-12-19T16:30:04.937Z',
        activities: [
          {
            _id: '63aaf4f3dc537bb349b70cb1',
            name: 'Số lượng SMTT không đạt trước thanh trùng (mL)',
            activity_target: 0,
            measure: '%',
            sub_category_id: '63a0918ce3ab20bbdd79386e',
            createdAt: '2022-12-27T13:36:51.855Z',
            updatedAt: '2022-12-27T13:36:51.855Z',
            currentValue: 467,
          },
          {
            _id: '63aaf50adc537bb349b70cb3',
            name: 'Số lượng SMTT không đạt sau thanh trùng (mL)',
            activity_target: 0,
            measure: '%',
            sub_category_id: '63a0918ce3ab20bbdd79386e',
            createdAt: '2022-12-27T13:37:14.370Z',
            updatedAt: '2022-12-27T13:37:14.370Z',
            currentValue: 467,
          },
          {
            _id: '63aaf519dc537bb349b70cb5',
            name: 'Số lượng SMTT không đạt trước và sau thanh trùng (mL)',
            activity_target: 0,
            measure: '%',
            sub_category_id: '63a0918ce3ab20bbdd79386e',
            createdAt: '2022-12-27T13:37:29.442Z',
            updatedAt: '2022-12-27T13:37:29.442Z',
            currentValue: 854,
          },
        ],
      },
      {
        _id: '63a0924de3ab20bbdd793878',
        sub_name: 'Tổng số SMHT bị huỷ hoặc hao hụt do các lý do (mL)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:33:17.091Z',
        updatedAt: '2022-12-19T16:33:17.091Z',
        activities: [],
        currentValue: 53,
      },
      {
        _id: '63a0925ae3ab20bbdd79387a',
        sub_name: 'Tỷ lệ hủy sữa (%)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:33:30.844Z',
        updatedAt: '2022-12-19T16:33:30.844Z',
        activities: [],
        currentValue: 44,
      },
      {
        _id: '63a0926ce3ab20bbdd79387c',
        sub_name: 'Tổng số SMTT có tại ngân hàng ở thời điểm báo cáo (mL)',
        category_id: '63a08f2de3ab20bbdd793844',
        createdAt: '2022-12-19T16:33:48.903Z',
        updatedAt: '2022-12-19T16:33:48.903Z',
        activities: [],
        currentValue: 79,
      },
    ],
  },
  {
    _id: '63a08f14e3ab20bbdd793842',
    name: 'Tuyển chọn, sàng lọc, và quản lý người hiến tặng',
    subcategories: [
      {
        _id: '63a090c9e3ab20bbdd79385a',
        sub_name: 'Số lần tư vấn nhóm',
        category_id: '63a08f14e3ab20bbdd793842',
        createdAt: '2022-12-19T16:26:49.926Z',
        updatedAt: '2022-12-19T16:26:49.926Z',
        activities: [],
        currentValue: 56,
      },
      {
        _id: '63a090d5e3ab20bbdd79385c',
        sub_name:
          'Số sự kiện (có số người tham dự lớn hơn 10, bao gồm cả truyền thông đại chúng, các hoạt động tạo nhu cầu tại bệnh viện và cộng đồng)',
        category_id: '63a08f14e3ab20bbdd793842',
        createdAt: '2022-12-19T16:27:01.550Z',
        updatedAt: '2022-12-19T16:27:01.550Z',
        activities: [],
        currentValue: 78,
      },
      {
        _id: '63a090e1e3ab20bbdd79385e',
        sub_name:
          'Số bà mẹ tiếp cận được để trao đổi về việc tham gia hiến sữa cho NHSM bởi cán bộ NHSM, cán bộ y tế khác, hoặc tình nguyện viên (theo hình thức nhóm, cá nhân, sự kiện)',
        category_id: '63a08f14e3ab20bbdd793842',
        createdAt: '2022-12-19T16:27:13.627Z',
        updatedAt: '2022-12-19T16:27:13.627Z',
        activities: [],
        currentValue: 76,
      },
      {
        _id: '63a090eae3ab20bbdd793860',
        sub_name: 'Số bà mẹ tiềm năng',
        category_id: '63a08f14e3ab20bbdd793842',
        createdAt: '2022-12-19T16:27:22.999Z',
        updatedAt: '2022-12-19T16:27:22.999Z',
        activities: [],
        currentValue: 56,
      },
      {
        _id: '63a090f2e3ab20bbdd793862',
        sub_name: 'Số bà mẹ bắt đầu tham gia hiến tặng sữa trong tháng',
        category_id: '63a08f14e3ab20bbdd793842',
        createdAt: '2022-12-19T16:27:30.334Z',
        updatedAt: '2022-12-19T16:27:30.334Z',
        activities: [],
        currentValue: 98,
      },
      {
        _id: '63a090f9e3ab20bbdd793864',
        sub_name:
          'Số bà mẹ kết thúc tham gia (Ví dụ: tuổi con quá ngưỡng, bệnh tật, hoặc dừng hiến tặng sữa)',
        category_id: '63a08f14e3ab20bbdd793842',
        createdAt: '2022-12-19T16:27:37.160Z',
        updatedAt: '2022-12-19T16:27:37.160Z',
        activities: [],
        currentValue: 7,
      },
    ],
  },
];

export const indexInputs = {
  index0_1: '0_1',
  index0_2: '0_2',
  index0_3: '0_3',
  index0_4: '0_4',
  index0_5: '0_5',
  index0_6: '0_6',
  index0_7: '0_7',
  index1_1: '1_1',
  index1_2: '1_2',
  index1_3: '1_3',
  index1_4: '1_4',
  index1_5: '1_5',
  index1_6: '1_6',
  index2_1: '2_1',
  index2_1_1: '2_1_1',
  index2_1_2: '2_1_2',
  index2_2: '2_2',
  index2_2_1: '2_2_1',
  index2_2_2: '2_2_2',
  index2_3: '2_3',
  index2_3_1: '2_3_1',
  index2_3_2: '2_3_2',
  index2_4: '2_4',
  index2_4_1: '2_4_1',
  index2_4_2: '2_4_2',
  index2_5: '2_5',
  index2_5_1: '2_5_1',
  index2_5_1_1: '2_5_1_1',
  index2_5_1_2: '2_5_1_2',
  index2_5_1_3: '2_5_1_3',
  index2_5_2: '2_5_2',
  index2_5_2_1: '2_5_2_1',
  index2_5_2_2: '2_5_2_2',
  index2_5_2_3: '2_5_2_3',
  index2_6: '2_6',
  index2_6_1: '2_6_1',
  index2_6_2: '2_6_2',
  index2_7: '2_7',
  index2_7_1: '2_7_1',
  index2_7_2: '2_7_2',
  index2_8: '2_8',
  index2_8_1: '2_8_1',
  index2_8_2: '2_8_2',
  index3_1: '3_1',
  index3_2: '3_2',
  index3_3: '3_3',
  index4_1: '4_1',
  index5_1: '5_1',
  index5_2: '5_2',
  index5_3: '5_3',
  index5_4: '5_4',
  index5_5: '5_5',
}

// export const requiredActivities = ['0_1', '0_2', '0_3', '0_4', '0_5', '0_6', '0_7', '1_1', '1_2', '1_3', '1_4',
//   '1_5', '1_6', '2_1_1', '2_8_1', '3_1', '3_2', '3_3', '4_1', '5_2', '5_3', '5_4',]

export const requiredActivities = [indexInputs.index0_1, indexInputs.index0_2, indexInputs.index0_3, indexInputs.index0_4,
indexInputs.index0_5, indexInputs.index0_6, indexInputs.index0_7, indexInputs.index1_1, indexInputs.index1_2,
indexInputs.index1_3, indexInputs.index1_4, indexInputs.index1_5, indexInputs.index1_6, indexInputs.index2_1_1,
indexInputs.index2_8_1, indexInputs.index3_1, indexInputs.index3_2, indexInputs.index3_3, indexInputs.index4_1,
indexInputs.index5_2, indexInputs.index5_3, indexInputs.index5_4,];

const labels = [
  'T7/2021',
  'T8/2021',
  'T9/2021',
  'T10/2021',
  'T11/2021',
  'T12/2021',
  'T01/2022',
  'T02/2022',
  'T03/2022',
  'T04/2022',
  'T05/2022',
  'T06/2022',
  'T07/2022',
  'T08/2022',
  'T09/2022',
  'T10/2022',
  'T11/2022',
  'T12/2022',
];

export const dataCharts = {
  //	THEO DÕI TÌNH TRẠNG HIẾN TẶNG SỮA CHO NGÂN HÀNG SỮA MẸ
  data1: {
    title: 'Số lượng bà mẹ hiến tặng mới & dừng hiến tặng',
    data: {
      labels,
      datasets: [
        {
          label: 'BMHT mới',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 10 })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'BM dừng HT',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 10 })),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    },
  },
  data2: {
    title: 'Lượng sữa mẹ hiến tặng thô (mL)',
    data: {
      labels,
      datasets: [
        {
          label: '',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 160000 })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    },
  },
  //THEO DÕI NHU CẦU SỬ DỤNG SỮA MẸ THANH TRÙNG
  data3: {
    title: 'Lượng sữa mẹ thanh trùng cho trẻ ăn (mL)',
    data: {
      labels,
      datasets: [
        {
          label: '',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 140000 })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    },
  },
  data4: {
    title: 'Số lượng trẻ sử dụng sữa',
    data: {
      labels,
      datasets: [
        {
          label: '',
          data: labels.map(() => faker.datatype.number({ min: 480, max: 660 })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    },
  },
  //	THEO DÕI HOẠT ĐỘNG THANH TRÙNG
  data5: {
    title: 'Lượng SMHT đem thanh trùng (mL)',
    data: {
      labels,
      datasets: [
        {
          label: '',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 160000 })),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    },
  },
  data6: {
    title: 'Tỷ lệ hủy sữa (%)',
    data: {
      labels,
      datasets: [
        {
          label: '',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 25 })),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    },
  },
  data7: {
    title: 'Lượng sữa không đạt trước và sau thanh trùng (mL)',
    data: {
      labels,
      datasets: [
        {
          label: 'Lượng sữa không đạt trước TT',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 30000 })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Lượng sữa không đạt sau TT',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 5000 })),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    },
  },
};

export const startDateLists = [
  { hospital: 'Hùng Vương', startMonth: 8, startYear: 2022 },
  { hospital: 'Bệnh viện Nhi Trung Ương', startMonth: 3, startYear: 2022 },
  { hospital: 'Quảng Ninh', startMonth: 7, startYear: 2020 },
  { hospital: 'Từ Dũ', startMonth: 4, startYear: 2019 },
  { hospital: 'Đà Nẵng', startMonth: 2, startYear: 2017 },
  { hospital: 'Quảng Nam', startMonth: 7, startYear: 2020 },
  { hospital: 'Phương Châu', startMonth: 1, startYear: 2021 },
  { hospital: 'Bệnh viện Đa khoa Quảng Trị', startMonth: 4, startYear: 2024 },
];
