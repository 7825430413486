export type MessageTypes = 'success' | 'error';
export interface IAppMessageState {
	message: string;
	isOpen: boolean;
	severity?: MessageTypes;
}

export const initialAppMessageState: IAppMessageState = {
	message: '',
	isOpen: false,
	severity: 'success',
};
