import { fork, all } from 'redux-saga/effects';
import { rootLogin } from './login';
import { rootGetHospitals } from './dashboard/admin';
import { rootGetFormReport } from './form-report';
import { rootSendReport } from './send-report';
import { rootGetHospitalDetail } from './hospital-detail';
import { rootGetProfile } from './profile';
import { rootGetHospitalReports } from './hospital-reports';
import { rootGetReports } from './reports/reports.saga';
import { rootGetHospitalInfor } from './hospital-information';
import { rootDeleteHospitalDetail } from './delete-hospital';
import { rootGetAllUser } from './all-users';
import { rootCreateAccount } from './dashboard/create-account';
import { rootDeleteReport } from './delete-report';

export function* rootSaga() {
  yield all([
    fork(rootLogin),
    fork(rootGetHospitals),
    fork(rootGetFormReport),
    fork(rootSendReport),
    fork(rootGetHospitalDetail),
    fork(rootGetProfile),
    fork(rootGetHospitalReports),
    fork(rootGetReports),
    fork(rootGetHospitalInfor),
    fork(rootDeleteHospitalDetail),
    fork(rootGetAllUser),
    fork(rootCreateAccount),
    fork(rootDeleteReport),
  ]);
}
