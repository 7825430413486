import { useRoutes } from 'react-router-dom';
import { Suspense } from 'react';
import { AlertMessage } from './components/AlertMessage';
import 'react-toastify/dist/ReactToastify.css';
import { protectedRoutes } from './Routes';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

Chart.defaults.set('plugins.datalabels', {
  align: 'top',
  font: {
    size: 10, // Adjust the font size of the labels
  },
  offet: -2,
  display: false,
});

Chart.defaults.set({
  devicePixelRatio: 4,
});

Chart.defaults.set('scales.y', {
  beginAtZero: true,
  min: 0,
});

function App() {
  const protectedElements = useRoutes(protectedRoutes);

  return (
    <div className='w-full h-full'>
      <AlertMessage />
      <Suspense
        fallback={
          <span
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
            }}
            className='bg-white dark:bg-slate-800'
          />
        }
      >
        {protectedElements}
      </Suspense>
    </div>
  );
}

export default App;
