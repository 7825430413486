import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

export type SingleLinkProps = {
	icon?: React.ReactNode;
	name: string;
	to: string;
	canAccess?: boolean;
};

export const SingleLink: React.FC<SingleLinkProps> = ({ icon, name, to, canAccess = false }) => {
	const { pathname } = useLocation();

	const isActive = useMemo(() => {
		return pathname === to;
	}, [pathname, to]);

	if (!canAccess) return null;

	const activatedStyle = isActive ? 'bg-blue hover:bg-sky-700 text-white' : 'hover:bg-gray-100'

	return (
		<li className='relative mb-2 px-4'>
			<Link
				className={`inline-flex items-center p-2 w-full text-sm font-bold rounded-md ${activatedStyle}`}
				to={to}
			>
				{!!icon && icon}
				<span className='ml-4'>{name}</span>
			</Link>
		</li>
	);
};
