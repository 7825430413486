import sponsor from 'assets/sponsor.png';
import { IconButton } from 'components/Button';
import { InputController } from 'components/Input';
import { Logo } from 'components/Logo';
import { KEY_INFO_USER, KEY_TOKEN, PERMISSIONS } from 'constants/app-const';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IFormLogin, login } from 'stores/login';
import { IAppState } from 'stores/state';

export const LoginAdmin = () => {
  const { isLoading } = useSelector((state: IAppState) => state.login);
  const loginResult = useSelector((state: IAppState) => state.login);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormLogin>();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmitLogin = (values: IFormLogin) => {
    dispatch(login(values));
  };

  useEffect(() => {
    if (loginResult.data) {
      localStorage.setItem(
        KEY_INFO_USER,
        JSON.stringify({
          user: loginResult.data.user,
        }),
      );
      localStorage.setItem(KEY_TOKEN, loginResult.data.access_token);
      localStorage.setItem(PERMISSIONS, JSON.stringify(loginResult.data.permissions));
      navigate('/admin/dashboard');
    }
  }, [loginResult.data, navigate, dispatch]);

  return (
    <div className='w-full grid grid-cols-1 md:grid-cols-5'>
      {/* Start banner block */}
      <div className='relative hidden md:block md:col-span-3 bg-background-login bg-cover bg-center'>
        <div className='!mt-5 ml-5'>
          <div>
            <Logo />
          </div>
        </div>
        <div className='absolute bottom-0 left-0 right-0 bg-slate-overlay'>
          <div className='w-full flex justify-around items-center text-[#FFF]'>
            <div>
              <span className='text-lg'>© Bản quyền thuộc Bộ Y tế</span>
            </div>
            <div className='flex items-center'>
              <span>Hỗ trợ xây dựng bởi</span>
              <img className='' src={sponsor} alt='' />
            </div>
            <div>
              <span>Ảnh: Alive & Thrive</span>
            </div>
          </div>
        </div>
      </div>
      {/* End banner block */}

      {/* Start form block */}
      <div className='w-full col-span-1 md:col-span-2'>
        <div className='w-full h-screen flex items-center'>
          <div className='w-full pt-5'>
            <div className='text-center'>
              <span className='text-2xl font-bold uppercase'>Báo cáo trực tuyến</span>
              <br />
              <span className='text-2xl font-bold uppercase'>ngân hàng sữa mẹ</span>
            </div>
            <form
              className='flex flex-col px-3 md:!px-16'
              onSubmit={handleSubmit(handleSubmitLogin)}
            >
              <div className='flex flex-col pt-4'>
                <p className='text-lg font-bold mb-2'>Tên Ngân hàng sữa mẹ</p>
                <InputController
                  control={control}
                  name='email'
                  placeholder='nhsm.quangninh'
                  error={errors?.email?.message && errors.email.message}
                  rules={{
                    required: 'Trường này là bắt buộc.',
                  }}
                />
              </div>
              <div className='flex flex-col pt-4 mb-12'>
                <p className='text-lg font-bold mb-2'>Mật khẩu</p>
                <InputController
                  control={control}
                  name='password'
                  placeholder='********'
                  type='password'
                  error={errors?.password?.message && errors.password.message}
                  rules={{
                    required: 'Trường này là bắt buộc.',
                  }}
                />
              </div>
              <IconButton variant='Contained' title='Đăng nhập' loading={isLoading} />
            </form>
            <div className='pt-12 pb-12 text-center'>
              <div>
                Bạn cần trợ giúp?
                <span className='underline cursor-pointer font-medium w-fit mr-auto ml-2'>
                  Liên hệ với chúng tôi
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End form block */}
    </div>
  );
};
