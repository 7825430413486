import React, { FC } from 'react';
import { KEY_INFO_USER, PERMISSIONS } from '../../constants/app-const';
import { Navigate } from 'react-router-dom';

type PrivatePageProps = {
	canAccess?: boolean;
	element: React.ReactNode;
};
export const PrivatePageAdmin: FC<PrivatePageProps> = ({ canAccess, element }) => {
	const infoUser: any = localStorage.getItem(KEY_INFO_USER);
	if (infoUser) {
		let inforParsed = JSON.parse(infoUser);
		if (inforParsed?.user.role === 'admin') {
			return <>{element}</>;
		}
		if (inforParsed.user.role === 'admin' && !canAccess) {
			return <Navigate to='/' />;
		}
	}
	localStorage.setItem(KEY_INFO_USER, '');
	localStorage.setItem(PERMISSIONS, '');

	return <Navigate to='/' />;
};

export const PrivatePageAgency: FC<PrivatePageProps> = ({ element }) => {
	const infoUser: any = localStorage.getItem(KEY_INFO_USER);
	if (infoUser) {
		let inforParsed = JSON.parse(infoUser);
		if (inforParsed?.user.role === 'agency' || inforParsed?.user.role === 'admin') {
			return <>{element}</>;
		}
	}
	localStorage.setItem(KEY_INFO_USER, '');
	localStorage.setItem(PERMISSIONS, '');

	return <Navigate to='/' />;
};

export const PrivatePageClient: FC<PrivatePageProps> = ({ element }) => {
	const infoUser: any = localStorage.getItem(KEY_INFO_USER);
	if (infoUser) {
		let inforParsed = JSON.parse(infoUser);
		if (
			inforParsed?.user.role === 'agency' ||
			inforParsed?.user.role === 'admin' ||
			inforParsed?.user.role === 'client'
		) {
			return <>{element}</>;
		}
	}
	localStorage.setItem(KEY_INFO_USER, '');
	localStorage.setItem(PERMISSIONS, '');
	return <Navigate to='/' />;
};
