import { GlobalReducer } from '../app';
import { Reducer } from 'redux';
import { initialResponseState, IResponseState } from '../state';
import { HospitalDetailAction, HospitalDetailTypes } from './hospitalDetail.type';
import { IGetHospitalAction, IGetHospitalsAction } from './hospitalDetail.interface';

export const HospitalDetailReducer: Reducer<IResponseState, IGetHospitalAction> = (
  state = initialResponseState,
  action: HospitalDetailAction,
) => GlobalReducer(state, action, HospitalDetailTypes.GET_HOSPITAL.toString());

export const GetHospitalsReducer: Reducer<IResponseState, IGetHospitalsAction> = (
  state = initialResponseState,
  action: HospitalDetailAction,
) => GlobalReducer(state, action, HospitalDetailTypes.GET_HOSPITALS.toString());
