import { FC } from 'react';

type DownloadIconProps = {
	stroke?: string;
	width?: string;
	height?: string;
};

const DownloadIcon: FC<DownloadIconProps> = ({ stroke, width, height }) => {
	return (
		<svg
			className={'w-18 sm:w-10 md:w-7'}
			viewBox='0 0 18 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.99997 15.5V8.74996M8.99997 15.5L7.12497 14M8.99997 15.5L10.875 14M3.77547 5.83771C3.06613 6.0162 2.44638 6.44758 2.03266 7.05079C1.61894 7.654 1.43973 8.38752 1.5287 9.11355C1.61767 9.83957 1.96869 10.5081 2.51582 10.9936C3.06295 11.4791 3.76852 11.748 4.49997 11.75H5.24997'
				stroke={stroke ? stroke : 'black'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.8725 4.35347C11.6162 3.3384 10.9834 2.45878 10.1026 1.89291C9.22175 1.32705 8.1587 1.11729 7.12894 1.30615C6.09918 1.49501 5.17975 2.06836 4.55704 2.90998C3.93434 3.75159 3.65494 4.7985 3.7755 5.83847C3.7755 5.83847 3.89025 6.49997 4.125 6.87497'
				stroke={stroke ? stroke : 'black'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.75 11.75C13.2795 11.7496 13.803 11.6371 14.2859 11.4199C14.7688 11.2027 15.2002 10.8856 15.5518 10.4896C15.9033 10.0936 16.167 9.62765 16.3255 9.1224C16.484 8.61714 16.5337 8.08405 16.4713 7.55821C16.4089 7.03237 16.2359 6.5257 15.9636 6.07155C15.6913 5.61741 15.3259 5.22609 14.8914 4.92334C14.457 4.62059 13.9633 4.41329 13.443 4.31507C12.9227 4.21685 12.3874 4.22995 11.8725 4.3535L10.875 4.625'
				stroke={stroke ? stroke : 'black'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
export default DownloadIcon;
