import { put, takeLatest } from '@redux-saga/core/effects';
import { handleActionFailed, handleActionRequested, handleActionSucceeded } from '../app';
import { BASE_URL, ENDPOINTS } from '../../api/endpoint';
import { AxiosResponse } from 'axios';
import { Requests } from '../../api/requests';
import { all, fork } from 'redux-saga/effects';
import { IGetReportsAction, IResponseGetReports } from './reports.interface';
import { GetReportsTypes } from './reports.type';

function* getReports({ type, payload }: IGetReportsAction) {
	yield put(handleActionRequested(type));
	const qs = '?' + new URLSearchParams(payload).toString();
	try {
		const apiUrl = BASE_URL.dev + ENDPOINTS.getReports + qs;
		const response: AxiosResponse = yield Requests.get(apiUrl);
		if (response.data) {
			yield put(handleActionSucceeded<IResponseGetReports>(type, response.data));
		} else {
			yield put(handleActionFailed(type, ''));
		}
	} catch (error: any) {
		const message = error.response.data.message ? error.response.data.message : error.message;
		yield put(handleActionFailed(type, message));
	}
}

function* watchGetReports() {
	yield takeLatest(GetReportsTypes.GET_REPORTS, getReports);
}

export function* rootGetReports() {
	yield all([fork(watchGetReports)]);
}
