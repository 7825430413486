import { GlobalReducer } from '../app';
import { Reducer } from 'redux';
import { initialResponseState, IResponseState } from '../state';
import { IDeleteReportAction } from './deleteReport.interface';
import { DeleteReportAction, DeleteReportTypes } from './deleteReport.type';

export const DeleteReportReducer: Reducer<IResponseState, IDeleteReportAction> = (
  state = initialResponseState,
  action: DeleteReportAction,
) => GlobalReducer(state, action, DeleteReportTypes.DELETE_REPORT.toString());
