const PlusIcon = () => {
	return (
		<svg
			width='15'
			height='15'
			viewBox='0 0 18 18'
			fill='currentColor'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.83337 17.1667V10.1667H0.833374V7.83334H7.83337V0.833344H10.1667V7.83334H17.1667V10.1667H10.1667V17.1667H7.83337Z'
				fill='currentColor'
			/>
		</svg>
	);
};

export default PlusIcon;
